import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { KnexionCoreModule, RoutingModule } from 'knexion-core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, DEBUG_MODE, APP_VERSION, APP_NAME } from '@angular/fire/analytics';
import { RootStoreModule } from './root-store/root.store.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KnexionCoreModule.initializeApp(environment.firebaseConfig),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    RoutingModule,
    RootStoreModule,
    //AngularFirestoreModule.enablePersistence()
  ],
  providers: [
    { provide: 'ENVIRONMENT', useValue: environment },
    // Google Analytics
    { provide: APP_NAME, useValue: 'Knexion' },
    { provide: APP_VERSION, useValue: '0.1' },
    { provide: DEBUG_MODE, useValue: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
