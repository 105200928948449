import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private readonly title = 'Knexion';
  private readonly description = 'Connect with people like you today!';
  private readonly keywords = 'Knexion,connection,friends';
  private readonly robotsProduction = 'INDEX, NOFOLLOW';
  private readonly robotsNonProduction = 'NOFOLLOW, NOINDEX';

  constructor(private titleService: Title,
              private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTag({ 'name': 'description', 'content': this.description });
    this.metaService.addTag({ 'name': 'keywords', 'content': this.keywords });
    if (environment.production) {
      this.metaService.addTag({ 'name': 'robots', 'content': this.robotsProduction });
    } else {
      this.metaService.addTag({ 'name': 'robots', 'content': this.robotsNonProduction });
    }
  }
}
